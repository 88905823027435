import EntitiesView from 'Components/EntitiesView';
import { actions } from 'ducks/buildings';
import Show from 'Pages/Metadata/Show';
import React from 'react';
import { connect } from 'react-redux';
import columns from 'utils/columns/buildings';
import history from '~/history';

const onRowClick = event => history.push(`/buildings/${event.target.dataset.id}`);

const Components = props => {
    return (
        <EntitiesView
            columns={columns}
            onRowClick={onRowClick}
            showComponent={Show}
            {...props}
        />
    );
};

const mapStateToProps = ({ buildings: { allChecked, checked, ids, models, loading, searching, searchValue, sortBy, sortDirection } }) =>
    ({ allChecked, checked, ids, models, loading, searching, searchValue, sortBy, sortDirection });

const { changeSearch, changeSort, fetchAll, toggleAll, toggleOne } = actions;

const mapDispatchToProps = ({
    changeSearch,
    changeSort,
    fetchData: fetchAll,
    toggleAll,
    toggleOne: event => {
        event.stopPropagation();
        return toggleOne(event.target.name);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Components);
