import Table from 'Components/SimpleTable';
import React from 'react';
import { connect } from 'react-redux';
import styleTime from 'utils/styleTime';

const
    collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }),
    transformLastAcquisition = ({ timestamp }) => styleTime(timestamp);

const columns = [
    { label: 'Name', dataKey: 'name' },
    { label: 'Value', dataKey: 'value' },
    { label: 'Timestamp', dataKey: 'timestamp', transform: transformLastAcquisition }
];

const Component = props => {
    const { device, loading, rows } = props;

    let sortedRows = rows.sort((a, b) => collator.compare(a.name, b.name));
    if (device.tag === 'Relay Status') {
        sortedRows = sortedRows.map(row => {
            let value = row.value;
            if (value) {
                value = (row.value).toString(2).padStart(16, '0');
            }
            return { ...row, value };
        });
    }

    return (
        <Table columns={columns} loading={loading} rows={sortedRows}/>
    );
};

const mapStateToProps = state => ({
    loading: state.logs.loading,
    rows: state.logs.realtime,
    submitting: state.devices.submitting
});

export default connect(mapStateToProps)(Component);
